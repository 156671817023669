import React from "react"
import PropTypes from "prop-types";

export default class ParallaxContainer extends React.Component {
    constructor(props) {
        super(props);
        this.Container = React.createRef();
    }

    componentDidMount() {
        let windowWidth = document.documentElement.clientWidth;
        const element = this.Container.current;
        let speed = this.props.speed ? this.props.speed : 3;
        let offset = this.props.offset ? this.props.offset : 0;

        function parallax() {
            const bound = element.getBoundingClientRect();
            const trueAmount = (bound.bottom - window.innerWidth) - offset;
            element.style.backgroundPositionY = Math.round(trueAmount / speed) + "px";
        }

        function parallaxReverse() {
            const bound = element.getBoundingClientRect();
            const trueAmount = (bound.bottom - window.innerWidth) + offset;
            element.style.backgroundPositionY = -Math.round(trueAmount / speed) + "px";
        }

        if (windowWidth < 992) {
            element.style.backgroundPositionY = 'center';
        } else {
            if (this.props.reverseDirection) {
                parallaxReverse();
                document.addEventListener('scroll', parallaxReverse);
            } else {
                parallax();
                document.addEventListener('scroll', parallax);
            }
        }
    }

    render() {

        const bgImageURL = this.props.image;
        const size = (this.props.bgSize ? this.props.bgSize : 'cover');
        const position = (this.props.bgPos ? this.props.bgPos : 'center');
        const repeat = (this.props.repeat ? this.props.repeat : 'repeat-y');
        const defaultStyle = {
            backgroundColor: this.props.backgroundColor,
            backgroundImage: 'url(' + bgImageURL + ')',
            backgroundRepeat: repeat,
            backgroundSize: size,
            backgroundPositionX: position,
        };
        const Style = {...defaultStyle, ...this.props.style};

        return (
            <div ref={this.Container} style={Style} className={this.props.className}>
                {this.props.children}
            </div>
        )
    }
}

ParallaxContainer.propTypes = {
    className: PropTypes.string,
    image: PropTypes.string,
    bgSize: PropTypes.string,
    bgPos: PropTypes.string,
    style: PropTypes.object,
    offset: PropTypes.number,
    reverseDirection: PropTypes.bool,
    speed: PropTypes.number,
    repeat: PropTypes.oneOf(["no-repeat", "repeat", "repeat-x", "repeat-y"])
};
