import React from "react";
import PropTypes from "prop-types";
import {Margin, Padding, StringUtils, TextAlign} from "../../Utilities";
//import styles from "./flex.module.scss"

export const Flex = ({children, className, alignItems, justifyContent, vertical, p, pl, pr, px, pt, pb, py, m, ml, mr, mx, mt, mb, my, textAlign, textAlignMd, textAlignLg, ...props}) => {
    let flexClass = StringUtils.joinIgnoreEmpty(" ", "d-flex", className, (!vertical ? "" : 'flex-column'), (justifyContent ? "justify-content-" + justifyContent : ""), (alignItems ? "align-items-" + alignItems : ""));
    flexClass += Padding({p, pl, pr, px, pt, pb, py});
    flexClass += Margin({m, ml, mr, mx, mt, mb, my});
    flexClass += TextAlign(textAlign, textAlignMd, textAlignLg);
    return (
        <div className={flexClass} {...props}>
            {children}
        </div>
    )
};

Flex.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    justifyContent: PropTypes.oneOf(["center", "start", "end", "stretch", "between", "around"]),
    alignItems: PropTypes.oneOf(["center", "start", "end", "stretch", "baseline"]),
    vertical: PropTypes.bool,
    children: PropTypes.any,

    m: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    mt: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    mb: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    my: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    ml: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    mr: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    mx: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),

    p: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    pl: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    pr: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    px: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    pt: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    pb: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    py: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),

    pMd: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    plMd: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    prMd: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    pxMd: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    ptMd: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    pbMd: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    pyMd: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),

    pLg: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    plLg: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    prLg: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    pxLg: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    ptLg: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    pbLg: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
    pyLg: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "gap", 0, "auto"]),
};
